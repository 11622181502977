<template>
  <b-row>
    <b-col sm="12">
      <TableDataServerSide
        :ref="`tableUserInfoCreditPurchase`"
        :url="_url"
        @clickNew="$refs[`indexFormModalCreditPurchase`].show()"
        @clickView="$refs[`indexFormModalCreditPurchase`].show($event)"
        @clickEdit="$refs[`indexFormModalCreditPurchase`].show({ ...$event })"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/admin/userTransactionData', $event)"
        :columns="_columns"
        :newAble="false"
        viewAble
        :editAble="false"
        :deleteAble="false"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'IndexPurchaseCreditTable',
  props: {
    url: {
      type: String,
      default: '/api/admin/userTransactionData?filter=creditPurchase',
    },
  },
  computed: {
    _url() {
      return this.url
    },
    _role() {
      return this.role.role
    },

    _columns() {
      let columns = [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'user_as_email',
        },
        {
          label: 'ชื่อ สกุล',
          field: 'user_as_name',
        },
        {
          label: 'เลขอ้างอิง',
          field: 'transaction_data_as_outside_id',
        },
        {
          label: 'เครดิต เดิม',
          field: 'transaction_data_as_credit_balance',
          type: 'number',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิต เติม',
          field: 'transaction_data_as_credit_amount',
          type: 'number',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิต รวม',
          field: 'transaction_data_as_credit_total',
          type: 'number',
          tdClass: 'text-right',
        },
        {
          label: 'ช่องทางการเติม',
          field: 'transaction_data_as_payment_chanel',
          thClass: 'text-center',
          tdClass: 'text-center',
        },

        {
          label: 'สถานะ',
          field: 'transaction_data_as_status_text',
          tdClass: 'text-center',
        },
        {
          label: 'วันที่',
          field: 'created_at',
          formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]

      // columns.push({
      //   label: '',
      //   field: 'action',
      // })
      return columns
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
